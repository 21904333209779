import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IGenericPageSection } from 'interfaces/generic-page-section'
import styles from './style.module.css'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import { IMedia } from 'interfaces/media'
import Link from 'next/link'
import useMobileScreenDetection from 'hooks/useMobileScreenDetection'
import CaretIcon from 'icons/caret-icon'

interface Media {
    mobileMedia?: IMedia
    desktopMedia?: IMedia
}
interface IProps {
    section: IGenericPageSection
    medias?: Media[]
}

const lgBreakPoint = screens.lg

const headerRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => <div className="">{children}</div>,
}

export const ThreeInARowSection = ({ section }: IProps): React.ReactElement => {
    const [openAccordions, setOpenAccordions] = React.useState([0])
    const isMobileScreen = useMobileScreenDetection()

    if (!section) {
        return null
    }

    const {
        header,
        mobileMediaCollection,
        mediaCollection,
        primaryCtaLink,
        renderType,
        videoPosterImage,
        videoPosterImageMobile,
    } = section

    const medias: { mobileMedia: IMedia; desktopMedia: IMedia }[] = []

    for (let i = 0; i < Math.max(mobileMediaCollection?.items?.length, mediaCollection?.items?.length); i++) {
        medias.push({
            mobileMedia: mobileMediaCollection?.items?.[i],
            desktopMedia: mediaCollection?.items?.[i],
        })
    }

    const toggleAccordion = (index: number) => {
        setOpenAccordions((prev) => {
            if (prev.includes(index)) {
                return prev.filter((i) => i !== index)
            } else {
                return [...prev, index]
            }
        })
    }

    const mediaBlock = (
        <>
            {medias.length > 0 && (
                <div className="mt-8 lg:mt-12 grid grid-cols-1 md:grid-cols-3 gap-x-5 gap-y-7">
                    {medias.map((media, index) => {
                        const { mobileMedia, desktopMedia } = media

                        if (!mobileMedia && !desktopMedia) {
                            return null
                        }

                        const mediaTitle = mobileMedia?.title || desktopMedia?.title
                        const mediaDescription = mobileMedia?.description || desktopMedia?.description

                        return (
                            <div key={mobileMedia?.sys?.id || desktopMedia?.sys?.id || index}>
                                <picture className="relative">
                                    {desktopMedia?.url && (
                                        <>
                                            {/* desktop webp */}
                                            <source
                                                type="image/webp"
                                                media={`(min-width: ${lgBreakPoint})`}
                                                srcSet={`
                                         ${desktopMedia.url}?fm=webp&w=420 420w,
                                         ${desktopMedia.url}?fm=webp&w=640 640w,
                                         ${desktopMedia.url}?fm=webp&w=768 768w
                                     `}
                                            />

                                            {/* desktop jpeg */}
                                            <source
                                                type="image/jpeg"
                                                media={`(min-width: ${lgBreakPoint})`}
                                                srcSet={`
                                         ${desktopMedia.url}?fm=jpg&w=420 420w,
                                         ${desktopMedia.url}?fm=jpg&w=640 640w,
                                         ${desktopMedia.url}?fm=jpg&w=768 768w
                                     `}
                                            />
                                        </>
                                    )}

                                    {mobileMedia?.url && (
                                        <>
                                            {/* desktop webp */}
                                            <source
                                                type="image/webp"
                                                srcSet={`
                                         ${mobileMedia.url}?fm=webp&w=320 320w,
                                         ${mobileMedia.url}?fm=webp&w=420 420w,
                                         ${mobileMedia.url}?fm=webp&w=640 640w
                                     `}
                                            />

                                            {/* desktop jpeg */}
                                            <source
                                                type="image/jpeg"
                                                srcSet={`
                                         ${mobileMedia.url}?fm=jpg&w=375 375w,
                                         ${mobileMedia.url}?fm=jpg&w=420 420w,
                                         ${mobileMedia.url}?fm=jpg&w=640 640w
                                     `}
                                            />
                                        </>
                                    )}

                                    <img
                                        src={(mobileMedia?.url || desktopMedia?.url) + '?fm=jpg&w=640'}
                                        alt={mediaDescription || mediaTitle}
                                        height={mobileMedia?.height || desktopMedia?.height}
                                        width={mobileMedia?.width || desktopMedia?.width}
                                        loading="lazy"
                                        className="rounded-lg w-full h-[356px] object-cover"
                                    />
                                </picture>

                                <div className="mt-6 font-bold text-lg lg:text-1xl">{mediaTitle}</div>
                                <div className="mt-2.5">{mediaDescription}</div>
                            </div>
                        )
                    })}
                </div>
            )}
        </>
    )

    return (
        <>
            {renderType === 'Three In A Row' && (
                <section className="section py-15 lg:py-24 flex flex-col items-center">
                    {header?.json && (
                        <h2 className={`text-center ${styles['header']}`}>
                            <ContentfulRichText
                                content={header.json}
                                renderNode={headerRenderNode}
                                className="section--header text-3xl lg:text-3.25xl leading-110% lg:leading-120% font-normal"
                            />
                        </h2>
                    )}

                    {mediaBlock}

                    {primaryCtaLink?.href && (
                        <Link
                            href={primaryCtaLink.href}
                            className="button button-secondary no-hover section--primary-cta inline-block mt-8 lg:mt-15 w-full lg:w-auto mx-auto"
                        >
                            {primaryCtaLink.name}
                        </Link>
                    )}
                </section>
            )}

            {renderType === 'Three In A Row Left Aligned With Callouts' && (
                <section className="section py-15 lg:py-24 flex flex-col items-left mt-12">
                    {header?.json && (
                        <h2 className={`text-center ${styles['header']}`}>
                            <ContentfulRichText
                                content={header.json}
                                renderNode={headerRenderNode}
                                className="text-4xl text-left lg:text-6.25xl leading-110% lg:leading-130% font-medium"
                            />
                        </h2>
                    )}

                    {primaryCtaLink?.href && (
                        <div className="flex justify-start">
                            <Link
                                href={primaryCtaLink?.href}
                                className="button button-primary mt-6 inline-block min-w-200"
                            >
                                {primaryCtaLink?.name}
                            </Link>
                        </div>
                    )}

                    {mediaBlock}
                </section>
            )}

            {renderType === 'Three In A Row Center Aligned With Image' && (
                <section className="section pt-0 pb-8 lg:py-12 flex flex-col items-center">
                    {header?.json && (
                        <h2 className={`text-center ${styles['header']}`}>
                            <ContentfulRichText
                                content={header.json}
                                renderNode={headerRenderNode}
                                className="text-3xl lg:text-3.25xl leading-110% lg:leading-120% font-normal px-6 sm:px-0 tracking-tight"
                            />
                        </h2>
                    )}

                    <div className="mt-8 lg:mt-12 grid grid-cols-1 lg:grid-cols-4 xl:px-16 gap-4">
                        <div className="lg:col-span-3">
                            <MediaList
                                medias={medias}
                                openAccordions={openAccordions}
                                toggleAccordion={toggleAccordion}
                                isMobile={isMobileScreen}
                            />
                        </div>
                        <div className="lg:col-span-1">
                            <ImageSection
                                videoPosterImageMobile={videoPosterImageMobile}
                                videoPosterImage={videoPosterImage}
                            />
                        </div>
                    </div>

                    {primaryCtaLink?.href && (
                        <div className="flex justify-start lg:mt-8">
                            <Link
                                href={primaryCtaLink?.href}
                                className="button button-primary hover:bg-transparent hover:text-black transition-colors duration-200 mt-6 inline-block min-w-[300px]"
                            >
                                {primaryCtaLink?.name}
                            </Link>
                        </div>
                    )}
                </section>
            )}
        </>
    )
}

const MediaItem = ({ media, index, isOpen, toggleAccordion, isMobile }) => {
    let numberBg = ''
    switch (index % 3) {
        case 0:
            numberBg = 'bg-painFever'
            break
        case 1:
            numberBg = 'bg-green'
            break
        case 2:
        default:
            numberBg = 'bg-mustard'
    }
    return (
        <div className="bg-white rounded-4xl py-2 px-6 lg:py-6 lg:px-8 xl:py-8 flex flex-col items-start h-full lg:h-[384px] tracking-tight">
            {isMobile ? (
                <>
                    <button
                        className="w-full flex flex-col items-start text-left"
                        onClick={() => toggleAccordion(index)}
                        disabled={index === 0}
                    >
                        <div className="flex items-center w-full">
                            <div className="mr-4">
                                <div
                                    className={`w-8 h-8 sm:w-10 sm:h-10 rounded-full ${numberBg} flex items-center justify-center`}
                                >
                                    <span className="font-bold text-sm text-white">{index + 1}</span>
                                </div>
                            </div>
                            <div className="text-lg h-16 flex items-center overflow-hidden flex-grow tracking-tight">
                                <span>{media.mobileMedia?.title || media.desktopMedia?.title}</span>
                            </div>
                            <div className="ml-auto">
                                {isOpen ? <CaretIcon direction="up" /> : <CaretIcon direction="down" />}
                            </div>
                        </div>
                    </button>
                    {isOpen && (
                        <div className="mt-5 pb-8">
                            <p>{media.mobileMedia?.description || media.desktopMedia?.description}</p>
                        </div>
                    )}
                </>
            ) : (
                <>
                    <div className="w-full">
                        <div
                            className={`w-4 h-4 sm:w-6 sm:h-6 rounded-full ${numberBg} flex items-center justify-center mb-4`}
                        >
                            <span className="font-bold text-sm text-white">{index + 1}</span>
                        </div>
                        <div className="text-lg lg:text-2xl 2xl:text-3xl lg:mt-12 lg:mb-4 h-16 flex items-center tracking-tight">
                            <span>{media.mobileMedia?.title || media.desktopMedia?.title}</span>
                        </div>
                    </div>
                    <div className="mt-5 lg:px-0 flex-grow overflow-hidden">
                        <p>{media.mobileMedia?.description || media.desktopMedia?.description}</p>
                    </div>
                </>
            )}
        </div>
    )
}
const MediaList = ({ medias, openAccordions, toggleAccordion, isMobile }) => (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 w-full">
        {medias.slice(0, 3).map((media, index) => (
            <MediaItem
                key={index}
                media={media}
                index={index}
                isOpen={openAccordions.includes(index)}
                toggleAccordion={toggleAccordion}
                isMobile={isMobile}
            />
        ))}
    </div>
)

const ImageSection = ({ videoPosterImageMobile, videoPosterImage }) => (
    <div className="mt-4 lg:mt-0 lg:ml-4 w-full lg:w-[287.5px] lg:h-[335px]">
        <picture>
            {/* Mobile webp */}
            <source
                type="image/webp"
                media={`(max-width: ${lgBreakPoint})`}
                srcSet={`
                    ${videoPosterImageMobile?.url}?fm=webp&w=375 375w,
                    ${videoPosterImageMobile?.url}?fm=webp&w=640 640w,
                    ${videoPosterImageMobile?.url}?fm=webp&w=768 768w
                `}
            />
            {/* Mobile jpeg */}
            <source
                type="image/jpeg"
                media={`(max-width: ${lgBreakPoint})`}
                srcSet={`
                    ${videoPosterImageMobile?.url}?fm=jpg&w=375 375w,
                    ${videoPosterImageMobile?.url}?fm=jpg&w=640 640w,
                    ${videoPosterImageMobile?.url}?fm=jpg&w=768 768w
                `}
            />
            {/* Desktop webp */}
            <source
                type="image/webp"
                media={`(min-width: ${lgBreakPoint})`}
                srcSet={`
                    ${videoPosterImage?.url}?fm=webp&w=640 640w,
                    ${videoPosterImage?.url}?fm=webp&w=1024 1024w,
                    ${videoPosterImage?.url}?fm=webp&w=1280 1280w
                `}
            />
            {/* Desktop jpeg */}
            <source
                type="image/jpeg"
                media={`(min-width: ${lgBreakPoint})`}
                srcSet={`
                    ${videoPosterImage?.url}?fm=jpg&w=640 640w,
                    ${videoPosterImage?.url}?fm=jpg&w=1024 1024w,
                    ${videoPosterImage?.url}?fm=jpg&w=1280 1280w
                `}
            />
            <img
                src={(videoPosterImageMobile?.url || videoPosterImage?.url) + '?fm=jpg&w=640'}
                alt={videoPosterImageMobile?.title || videoPosterImage?.title}
                className="rounded-4xl w-full h-60 sm:h-96 object-cover"
                loading="lazy"
            />
        </picture>
    </div>
)
