import { IPageSectionIcons } from 'interfaces/page-section-icons'
import * as React from 'react'
import { getHTMLClassTypeNameFromSection } from 'utils/css'

interface IProps {
    section: IPageSectionIcons
}

const BigIconsWithTextSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { iconsCollection, backgroundColor } = section
    const icons = iconsCollection.items

    return (
        <section className={`${getHTMLClassTypeNameFromSection(section)} section--v2`} style={{ backgroundColor }}>
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-10 lg:gap-32 py-12.5 mx-auto">
                {icons.map((icon) => {
                    return (
                        <div key={icon.key} className="flex flex-col items-center">
                            <picture>
                                {icon.media.url.endsWith('.svg') && (
                                    <source type="image/svg+xml" srcSet={icon.media.url} />
                                )}

                                <source type="image/webp" srcSet={icon.media.url + '?fm=webp&w=800'} />
                                <source type="image/jpeg" srcSet={icon.media.url + '?fm=jpg&w=800'} />

                                <img
                                    src={icon.media.url + '?fm=jpg&w=800'}
                                    height={icon.media.height}
                                    width={icon.media.width}
                                    aria-hidden
                                    alt={icon.media.title}
                                    loading="lazy"
                                />
                            </picture>

                            <div className="text-lg lg:text-2xl text-center font-medium leading-120% mt-4 lg:mt-2.5">
                                {icon.value}
                            </div>
                        </div>
                    )
                })}
            </div>
        </section>
    )
}

export default BigIconsWithTextSection
