/* eslint-disable react/display-name */
import * as React from 'react'
import ContentfulRichText from 'components/rich-text'
import { IPageSectionIcons } from 'interfaces/page-section-icons'
import { BLOCKS } from '@contentful/rich-text-types'
import twConfig from 'tailwind-light-config'
import { getHTMLClassTypeNameFromSection } from 'utils/css'
const { screens } = twConfig

interface IProps {
    section: IPageSectionIcons
}

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="section--header section--header-max-width mx-auto leading-120% tracking-tight font-medium lg:text-center">
            {children}
        </p>
    ),
}

const headerWithBackgroundImageRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="section--header section--header-max-width mx-auto text-1xl lg:text-3.25xl leading-120% tracking-tight font-medium text-center">
            {children}
        </p>
    ),
}

const subHeaderRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-xsm tracking-wider font-bold text-center">{children}</p>
    ),
}

const lgBreakPoint = screens.lg

export const IconsImpactSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { header, subHeader, iconsCollection, backgroundImage, backgroundColor, mobileBackgroundImage, textColor } =
        section

    const hasBackgroundImage = mobileBackgroundImage?.url || backgroundImage?.url

    return (
        <section
            className={`${getHTMLClassTypeNameFromSection(
                section,
            )} section--v2 lg:py-0 relative overflow-hidden flex flex-col lg:items-center ${
                hasBackgroundImage || backgroundColor
                    ? 'max-lg:section--small-margin-top max-lg:-mb-[var(--sectionBlockMarginNegativeAdjustment)]'
                    : ''
            }`}
            style={{ color: textColor }}
        >
            <div
                className={`w-full lg:relative lg:rounded-2lg overflow-hidden ${
                    hasBackgroundImage ? '-mx-5 sm:-mx-6 lg:mx-0' : ''
                }`}
            >
                {hasBackgroundImage && (
                    <picture>
                        {backgroundImage?.url && (
                            <>
                                <source
                                    type="image/webp"
                                    srcSet={`
                                        ${backgroundImage.url}?fm=webp&w=1280 1280w,
                                        ${backgroundImage.url}?fm=webp&w=1600 1600w
                                    `}
                                    media={`(min-width: ${lgBreakPoint})`}
                                />

                                <source
                                    type="image/jpeg"
                                    srcSet={`
                                        ${backgroundImage.url}?fm=jpg&w=1280 1280w,
                                        ${backgroundImage.url}?fm=jpg&w=1600 1600w
                                    `}
                                    media={`(min-width: ${lgBreakPoint})`}
                                />
                            </>
                        )}

                        {mobileBackgroundImage?.url && (
                            <>
                                <source
                                    type="image/webp"
                                    srcSet={`
                                        ${backgroundImage.url}?fm=webp&w=420 420w,
                                        ${backgroundImage.url}?fm=webp&w=768 768w,
                                        ${backgroundImage.url}?fm=webp&w=1024 1024w
                                    `}
                                />

                                <source
                                    type="image/jpeg"
                                    srcSet={`
                                        ${backgroundImage.url}?fm=jpg&w=420 420w,
                                        ${backgroundImage.url}?fm=jpg&w=768 768w,
                                        ${backgroundImage.url}?fm=jpg&w=1024 1024w
                                    `}
                                />
                            </>
                        )}

                        <img
                            src={backgroundImage.url + '?fm=jpg&w=1024'}
                            alt={backgroundImage.description || backgroundImage.title}
                            height={backgroundImage.height}
                            width={backgroundImage.width}
                            loading="lazy"
                            className="absolute w-full h-full left-0 top-0 object-cover"
                        />
                    </picture>
                )}

                <div className={`relative z-10 ${hasBackgroundImage ? 'px-5 sm:px-6 lg:px-16 lg:py-24' : ''}`}>
                    {subHeader?.json && (
                        <ContentfulRichText
                            renderNode={subHeaderRenderNode}
                            content={subHeader.json}
                            className="lg:text-center mx-auto"
                        />
                    )}

                    {header?.json && (
                        <ContentfulRichText
                            renderNode={hasBackgroundImage ? headerWithBackgroundImageRenderNode : headerRenderNode}
                            content={header.json}
                            className="mt-5"
                        />
                    )}

                    {/* icons */}
                    {iconsCollection?.items?.length > 0 && (
                        <div className="mt-10 lg:mt-16 flex flex-col lg:flex-row lg:justify-center space-y-3.5 lg:space-x-5 lg:space-y-0">
                            {iconsCollection.items.map(
                                (icon) =>
                                    icon?.media?.url && (
                                        <div
                                            key={icon.key}
                                            className="flex lg:flex-col items-center space-x-5 lg:space-x-0"
                                        >
                                            <picture className="flex items-center justify-center">
                                                {/* svg */}
                                                {icon.media?.url?.endsWith('.svg') && (
                                                    <source type="image/svg+xml" srcSet={icon.media?.url} />
                                                )}
                                                {/* webp */}
                                                <source type="image/webp" srcSet={icon.media?.url + '?w=200&fm=webp'} />
                                                {/* jpeg */}
                                                <source type="image/jpeg" srcSet={icon.media?.url + '?w=200&fm=jpg'} />

                                                <img
                                                    alt={
                                                        (icon.value as string) ||
                                                        icon.media?.description ||
                                                        icon.media?.title
                                                    }
                                                    src={icon.media?.url + '?w=200&fm=jpg'}
                                                    height={icon.media?.height}
                                                    width={icon.media?.width}
                                                    loading="lazy"
                                                    className="h-7 w-8 lg:h-11 lg:w-10 object-contain"
                                                />
                                            </picture>

                                            {/* divier in dekstop layout */}
                                            <div
                                                className="hidden lg:block h-px bg-black w-full mt-5"
                                                style={{ backgroundColor: textColor }}
                                            />

                                            <div className="text-xbase lg:text-base leading-140% font-medium lg:mt-5 lg:max-w-[11rem] lg:text-center lg:px-5">
                                                {icon.value}
                                            </div>
                                        </div>
                                    ),
                            )}
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}
