import { ICommonCopyDict } from 'interfaces/common-copy'
import { IPageSectionTestimonials } from 'interfaces/page-section-testimonials'
import { IUiResources } from 'interfaces/ui-resource'
import * as React from 'react'
import FeaturedReviewsSection from './featured-reviews-section'
import FeaturedReviewSquareSection from './featured-reviews-square-section'
import SingleReviewSection from './single-review-section'
import TestimonialCarouselSection from './testimonial-carousel-section/testimonial-carousel-section'
import TestimonialsColouredCarouselSection from './testimonials-coloured-carousel-section'
import TestimonialsGridSection from './testimonials-grid-section'

interface IProps {
    section: IPageSectionTestimonials
    uiResources: IUiResources
    commonCopy: ICommonCopyDict
}

const PageSectionTestimonials = ({ section, uiResources, commonCopy }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    switch (section.renderType) {
        case 'Cabinet Team':
            return <TestimonialCarouselSection section={section} uiResources={uiResources} />
        case 'Featured Reviews':
            return (
                <FeaturedReviewsSection
                    header={section.header}
                    reviews={section.featuredReviewsCollection?.items}
                    bottomIllustration={section.sectionIllustration}
                    commonCopy={commonCopy}
                    subHeader={section.richSubHeader}
                    uiResources={uiResources}
                    sectionBackgroundColor={section.backgroundColor}
                />
            )
        case 'Featured Reviews - Square':
            return <FeaturedReviewSquareSection section={section} />
        case 'Grid':
            return <TestimonialsGridSection section={section} />
        case 'Single Review':
            return <SingleReviewSection section={section} />
        case 'Carousel - Coloured':
        case 'Carousel - Coloured, Left Aligned':
            return <TestimonialsColouredCarouselSection section={section} />
        default:
            return null
    }
}

export default PageSectionTestimonials
